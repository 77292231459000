.btn_text {
  display: inline-flex;
  white-space: nowrap;

  @media (max-width: 575px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.add_cart_button {
  display: flex;
  gap: 5px;
  border-radius: 12px;
  width: 100%;
}

.cart_icon {
  width: 20px;
  height: 20px;
}

.locationIcon {
  padding: 4.5px 7px;
}