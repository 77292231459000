.city {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #E0E0E0;
  border-radius: 2px;
}

.select_item {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.select_item > b {
  color: #5F5F5F;
}
